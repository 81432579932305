<template>
  <div class="c-d-flex ui-flex-col ui-flex-1">
    <div class="c-row c-horizontal-center">
      <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10 c-d-flex">
        <div class="c-d-flex ui-w-full ui-mb-4">
          <!-- Título Meu perfil -->
          <div class="c-d-flex ui-flex-1 c-text-h3">
            <c-skeleton-block
              v-if="loading"
              width="8rem"
            />
            <span v-else>
              {{ isVisitorView ? 'Perfil' : 'Meu perfil' }}
            </span>
          </div>
          <!-- Botão alterar perfil -->
          <div
            v-if="!isVisitorView"
            class="c-d-flex c-vertical-end"
          >
            <div
              v-if="loading"
              class="c-d-flex ui-h-4 ui-space-x-2"
            >
              <c-skeleton-circle size="1rem" />
              <c-skeleton-block width="6rem" />
            </div>
            <router-link
              v-else
              :to="{ name: 'alterar-perfil' }"
              data-cy="doChangeProfileDataLink"
            >
              <c-link
                class="c-text-gray-300 c-d-flex ui-whitespace-no-wrap"
                component="button"
              >
                <icon
                  name="Edit"
                  color="var(--c-color-gray-300)"
                />
                Alterar perfil
              </c-link>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Cards -->
    <div class="c-row c-horizontal-center sm:ui-flex-grow">
      <div class="c-col-full sm:c-col-1/2 lg:c-col-4 c-d-flex ui-flex-col">
        <card class="ui-mb-4">
          <!-- Skeleton localização -->
          <div
            v-if="loading"
            class="c-d-flex ui-h-4 ui-space-x-2"
          >
            <c-skeleton-circle size="1rem" />
            <c-skeleton-block width="10rem" />
            <c-skeleton-block width="2rem" />
          </div>
          <!-- Localização -->
          <div
            v-else
            class="c-d-flex"
          >
            <img
              src="@/assets/icons/location.svg"
              alt="location"
              class="ui-mr-4"
            >
            <span class="c-text-b1 c-text-gray-400">
              {{ location }}
            </span>
          </div>

          <!-- Redes sociais -->
          <div class="c-d-flex c-text-b2 c-text-gray-300 ui-mt-8">
            <c-skeleton-block
              v-if="loading"
              width="6rem"
            />
            <span v-else>Redes sociais</span>
          </div>
          <!-- Icones redes sociais -->
          <div
            class="ui-relative ui--mx-6 sm:ui-mx-0"
            v-if="!loading"
          >
            <div class="c-d-flex ui-overflow-x-auto ui-px-6 sm:ui-px-0 sm:ui-overflow-x-visible sm:ui-px-0 ui-py-1 ui--my-1 c-border-transparent ui-border">
              <div class="c-d-flex ui-flex-wrap sm:flex-no-wrap ui-space-y-4 ui-items-baseline horizontal-scroll-shadow">
                <a
                  v-for="item in user.socialNetworks"
                  :key="item.name"
                  :href="item.profileSocialNetworkUrlPath"
                  target="_blank"
                  class="ui-mr-4"
                  rel="nofollow"
                >
                  <img
                    :src="item.iconUrl"
                    :alt="item.name"
                  >
                </a>
                <span
                  class="c-text-b1"
                  v-if="!user.socialNetworks.length"
                >
                  Não informado
                </span>
              </div>
            </div>
          </div>
          <!-- Skeleton redes sociais -->
          <div
            class="ui-relative ui--mx-6 sm:ui-mx-0"
            v-else
          >
            <div class="c-d-flex ui-overflow-x-auto ui-px-6 sm:ui-px-0">
              <div class="c-d-flex ui-flex-wrap sm:flex-no-wrap ui-space-y-4 ui-items-baseline horizontal-scroll-shadow">
                <c-skeleton-circle
                  v-for="i in 5"
                  :key="i"
                  size="3rem"
                  class="ui-mr-4"
                />
              </div>
            </div>
          </div>
        </card>

        <card
          class="ui-mb-4"
          v-if="$isPreview('fase2')"
        >
          <!-- Toggler perfil público -->
          <div
            v-if="!loading"
            class="c-d-flex c-vertical-center"
          >
            <c-toggle
              v-model="user.publiclyAvailable"
              @input="changePubliclyAvailable"
              class="ui-mr-4"
              :disabled="loadingPublicAvailable"
            />
            <span class="c-text-b2 ui-whitespace-no-wrap c-text-gray-300">
              {{ publicProfileTogglerText }}
            </span>
            <div class="c-d-flex c-horizontal-end ui-flex-1">
              <!-- Info -->
              <div
                @click="showInfoModal(publicProfileTextInfo)"
                class="ui-cursor-pointer"
              >
                <icon
                  name="Help"
                  color="var(--c-color-gray-300)"
                />
              </div>
            </div>
          </div>
          <!-- Skeleton toggler perfil público -->
          <div
            v-else
            class="c-d-flex c-vertical-center ui-mb-8"
          >
            <div class="ui-rounded-full ui-w-10 ui-h-5 c-skeleton ui-mr-4" />
            <div class="ui-rounded-full ui-w-24 ui-h-4 c-skeleton" />
            <div class="c-d-flex c-horizontal-end ui-flex-1">
              <div class="ui-rounded-full ui-w-5 ui-h-5 c-skeleton" />
            </div>
          </div>

          <separator v-if="!loading" />

          <!-- Toggler Ver como visitante -->
          <div
            v-if="!loading && $isPreview('fase2')"
            class="c-d-flex ui-items-center"
          >
            <c-toggle
              :value="isVisitorView"
              @input="changeVisitorView"
              class="ui-mr-4"
            />
            <span class="c-text-b2 ui-whitespace-no-wrap c-text-gray-300">
              Ver perfil como visitante
            </span>
          </div>
          <!-- Skeleton toggler Ver como visitante -->
          <div
            v-else
            class="c-d-flex"
          >
            <div class="ui-rounded-full ui-w-10 ui-h-5 c-skeleton ui-mr-4" />
            <div class="ui-rounded-full ui-w-48 ui-h-4 c-skeleton" />
          </div>
        </card>

        <!-- Ilustração visão do visitante -->
        <div
          v-if="isVisitorView"
          class="c-d-none sm:c-d-flex ui-flex-grow ui-items-end"
        >
          <img src="@/assets/images/visitor-view-profile-illustration.svg">
        </div>
      </div>

      <div class="c-col-full sm:c-col-1/2 lg:c-col-6">
        <card class="ui-relative">
          <bg-quotation-marks />

          <!-- Biografia -->
          <div
            v-if="!loading"
            class="ui-relative"
          >
            <!-- Info -->
            <div
              @click="showInfoModal(aboutMeModalTextInfo)"
              v-if="!isVisitorView"
              class="ui-absolute ui-right-0 ui-cursor-pointer"
            >
              <icon
                name="Help"
                color="var(--c-color-gray-300)"
              />
            </div>

            <!-- Biografia -->
            <div class="c-d-flex ui-flex-col ui-py-4 sm:ui-py-16">
              <span
                v-if="!isVisitorView"
                class="c-text-b2 c-text-gray-300"
              >
                Biografia
              </span>
              <p class="ui-break-words c-text-b1 ui-mt-2 ui-whitespace-pre-line">
                {{ user.aboutMe || 'Não informado' }}
              </p>
            </div>
          </div>
          <!-- Skeleton biografia -->
          <div
            v-else
            class="ui-relative"
          >
            <div class="ui-rounded-full ui-w-4 ui-h-4 c-skeleton ui-absolute ui-right-0" />
            <div class="c-d-flex ui-flex-col ui-py-16 ui-space-y-2">
              <div class="ui-rounded-full ui-w-20 ui-h-4 c-skeleton" />
              <div class="ui-rounded-full ui-w-full ui-h-4 c-skeleton" />
              <div class="ui-rounded-full ui-w-64 ui-h-4 c-skeleton" />
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Icon, CToggle, CLink, CSkeletonCircle, CSkeletonBlock } from '@estrategiahq/coruja-web-ui';
import Card from '@/components/card';
import Separator from '@/components/separator';
import BgQuotationMarks from '@/pages/profile/meu-perfil/bg-quotation-marks';
import TextOnlyModal from '@/modals/text-only-modal';
import { ABOUT_ME, PUBLIC_PROFILE } from './modal-text-info';
import { UserStoreKeys } from '@/store/modules/user';

export default {
  name: 'MeuPerfilPage',
  components: {
    BgQuotationMarks,
    Card,
    Icon,
    CToggle,
    CLink,
    Separator,
    CSkeletonCircle,
    CSkeletonBlock,
  },
  props: {
    isVisitorView: Boolean,
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loadingPublicAvailable: false,
      aboutMeModalTextInfo: ABOUT_ME,
      publicProfileTextInfo: PUBLIC_PROFILE,
    };
  },
  computed: {
    publicProfileTogglerText() {
      if (this.user.publiclyAvailable) {
        return 'Perfil público';
      }
      return 'Tornar perfil público';
    },
    location() {
      return `${this.user.address?.city} / ${this.user.address?.state}`;
    },
    ...mapGetters({
      user: UserStoreKeys.GET_LOGGED_USER,
    }),
  },
  methods: {
    showInfoModal(text) {
      this.$modal.show({
        component: TextOnlyModal,
        props: { text },
      });
    },
    changePubliclyAvailable() {
      this.loadingPublicAvailable = true;
      this.$services.accounts.updatePubliclyAvailable(this.user.publiclyAvailable)
        .catch(error => {
          this.user.publiclyAvailable = !this.user.publiclyAvailable;
          throw error;
        })
        .finally(() => this.loadingPublicAvailable = false);
    },
    changeVisitorView() {
      this.$emit('update:is-visitor-view', !this.isVisitorView);
      if (this.isVisitorView) {
        this.$toast.show({
          type: 'warning',
          text: 'Visualização como visitante desativada',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.horizontal-scroll-shadow {
  flex: none;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 100%;
    background: linear-gradient(272.75deg, #fff 2.58%, rgba(255, 255, 255, 0) 97.71%);
  }

  &::before {
    left: 0;
    transform: rotate(180deg);
  }

  &::after {
    right: 0;
  }
}

@include c-mq(sm) {
  .horizontal-scroll-shadow {
    flex: initial;

    &::before,
    &::after {
      display: none;
    }
  }
}
</style>
