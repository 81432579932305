<template>
  <div class="ui-w-full ui-h-full">
    <div class="quotation-mark-bg ui-absolute ui-top-0 ui-left-0 ui-w-full ui-h-full" />
    <div class="quotation-mark-bg ui-absolute ui-bottom-0 ui-right-0 ui-transform ui-rotate-180 ui-w-full ui-h-full" />
  </div>
</template>

<script>
export default {
  name: 'BgQuotationMarks',
};
</script>

<style scoped lang="scss">
.quotation-mark-bg {
  background: no-repeat url(~@/assets/images/quotation-marks.svg);
  background-size: 88px;
}

@include c-mq(sm) {
  .quotation-mark-bg {
    background-size: unset;
  }
}
</style>
